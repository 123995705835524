import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { InlineCode } from '../../components/inline-code/inline-code';
import { ItalicText } from '../../components/italic-text/italic-text';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Accessibility is a choice. Designers can use color combinations with sufficient contrast, or pick colors
for purely aesthetic reasons. Web developers can implement a button with the native `}<InlineCode mdxType="InlineCode">{`button`}</InlineCode>{`
tag, or they use the generic `}<InlineCode mdxType="InlineCode">{`div`}</InlineCode>{` tag instead.`}</p>
    <p>{`In an ideal development process, accessibility is considered right from the start. The requirements clearly
state the needs of people with disabilities. Designers create mockups and prototypes which meet these requirements.
Developers implement the features with semantic markup. Testers verify that the web content meets the accessibility
requirements.`}</p>
    <p>{`In reality, accessibility is often treated as an afterthought. This can be especially problematic when it comes to
`}<a parentName="p" {...{
        "href": "https://medium.com/sketch-app-sources/why-should-you-build-a-ui-component-library-854656b91a96"
      }}>{`UI component libraries`}</a>{`.
I will talk about why it's important to check the accessibility of the library before you
write your first line of code. And what you should keep in mind when working with UI components.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQCAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAABAP/aAAwDAQACEAMQAAABzL3YkkNg/wD/xAAZEAADAQEBAAAAAAAAAAAAAAABAgMAERP/2gAIAQEAAQUCFGz8ZQoInJKbxQlYzO//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAbEAACAgMBAAAAAAAAAAAAAAAAAQIxEVGRof/aAAgBAQAGPwJa0KOMFobcSvSn0//EABwQAAIDAQADAAAAAAAAAAAAAAABESExcUGB0f/aAAgBAQABPyGhrgOgLJzTpiZd6GyhRkdfw88WYE7nYf/aAAwDAQACAAMAAAAQny//xAAWEQEBAQAAAAAAAAAAAAAAAAAAESH/2gAIAQMBAT8Q1H//xAAWEQEBAQAAAAAAAAAAAAAAAAAAIYH/2gAIAQIBAT8QjX//xAAdEAEBAAMAAgMAAAAAAAAAAAABEQAhMUFRYXHB/9oACAEBAAE/ECMSpoITyudUxLp5K8xM1ybifGu/eQI+ioUPeA6yiFNbebx1oI63x/c//9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "A toy is assembled from dozens of Lego bricks.",
          "title": "A toy is assembled from dozens of Lego bricks.",
          "src": "/static/42cb64cd5660164f84b0a37077afae7c/e5166/pexels-alena-darmel-lego-bricks.jpg",
          "srcSet": ["/static/42cb64cd5660164f84b0a37077afae7c/f93b5/pexels-alena-darmel-lego-bricks.jpg 300w", "/static/42cb64cd5660164f84b0a37077afae7c/b4294/pexels-alena-darmel-lego-bricks.jpg 600w", "/static/42cb64cd5660164f84b0a37077afae7c/e5166/pexels-alena-darmel-lego-bricks.jpg 1200w", "/static/42cb64cd5660164f84b0a37077afae7c/b17f8/pexels-alena-darmel-lego-bricks.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Photo: © Alena Darmel / pexels.com`}</em></p>
    <h2>{`The Advantages of UI Component Libraries`}</h2>
    <p>{`HTML elements are the basic building blocks of any website. But they don't cover all types of content and possible
use cases. Complex UI patterns like `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/ARIA/apg/patterns/accordion/"
      }}>{`accordions`}</a>{`,
`}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/ARIA/apg/patterns/carousel/"
      }}>{`carousels`}</a>{`, or `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/ARIA/apg/patterns/tabs/"
      }}>{`tabs`}</a>{`
require custom implementations anyway.`}</p>
    <p>{`To speed up development and ensure a consistent look and feel, a modern web application usually makes use of a
UI component library. They're a collection of design elements that are ready to use in your application. Usually, you
can apply a color theme and customize the functionality of the components.`}</p>
    <p>{`Hundreds of different component libraries are available for major web frameworks like `}<a parentName="p" {...{
        "href": "https://angular.io/"
      }}>{`Angular`}</a>{`,
`}<a parentName="p" {...{
        "href": "https://react.dev/"
      }}>{`React`}</a>{`, or `}<a parentName="p" {...{
        "href": "https://vuejs.org/"
      }}>{`Vue.js`}</a>{`. For example, if you like the Material design system, you
can use `}<a parentName="p" {...{
        "href": "https://material.angular.io/"
      }}>{`Angular Material`}</a>{`, `}<a parentName="p" {...{
        "href": "https://mui.com/"
      }}>{`Material UI`}</a>{`, or
`}<a parentName="p" {...{
        "href": "https://matsp.github.io/material-components-vue/"
      }}>{`Material Components Vue`}</a>{`.`}</p>
    <h2>{`Checking the Library's Accessibility`}</h2>
    <p>{`Here's a checklist for picking an accessible UI component library:`}</p>
    <ol>
      <li parentName="ol">{`Is accessibility mentioned on the library's website or documentation? For example, the Angular Material homepage states
that they offer: `}<ItalicText mdxType="ItalicText">{`“Internationalized and accessible components for everyone.”`}</ItalicText></li>
      <li parentName="ol">{`Does the documentation explain if and how the components are made accessible? The Angular Material documentation has
`}<a parentName="li" {...{
          "href": "https://material.angular.io/components/tabs/overview#accessibility"
        }}>{`dedicated accessibility sections`}</a>{` for every component.`}</li>
      <li parentName="ol">{`The documentation usually includes demo pages and examples for the different components. Inspect their markup and
`}<a parentName="li" {...{
          "href": "https://www.w3.org/WAI/WCAG21/Understanding/name-role-value.html"
        }}>{`accessibility properties`}</a>{` using your browser's dev tools.
Test with assistive technologies like your keyboard and different desktop and mobile screen readers.`}</li>
    </ol>
    <h2>{`No Guarantee of Complete Accessibility`}</h2>
    <p>{`Picking an accessible UI component library is an important first step towards making you web application accessible.
But it doesn't guarantee it. Every time you use a component on a page, you should answer the following questions:`}</p>
    <ul>
      <li parentName="ul">{`Where and how is the component included? Think about the context and order of content.`}</li>
      <li parentName="ul">{`Is the type of use consistent with the actual purpose of the component? Or are you only trying to achieve a specific
visual appearance?`}</li>
    </ul>
    <p>{`The visual presentation of content should not determine which component you use. It's easy to apply a specific design
with CSS. But it's nearly impossible or at least very tedious to override a component's semantic markup and implemented
keyboard interaction.`}</p>
    <p>{`To give you an example: Angular Material's `}<InlineCode mdxType="InlineCode">{`<`}{`mat-chip-listbox`}{`>`}</InlineCode>{` component provides a
multi-select listbox, following the `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/ARIA/apg/patterns/listbox/"
      }}>{`listbox pattern`}</a>{`. The semantic markup
communicates to assistive technologies that the element offers several options of which one or more can be selected.
The whole listbox receives keyboard focus via the `}<InlineCode mdxType="InlineCode">{`TAB`}</InlineCode>{` key. The arrow keys move focus to the next
or previous option.`}</p>
    <p>{`Now imagine your client saying: `}<ItalicText mdxType="ItalicText">{`“I love the design of the chips component. I want this list of links styled
in the same way!”`}</ItalicText>{` Do you think you should really use the component for this use case?`}</p>
    <p>{`It's our responsibility as web developers to pick the right component for the job. And to resist the seemingly easy path
that would lead to disaster! 😉`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      